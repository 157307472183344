import React from 'react';

function App() {
  return (
    <div>
      <h1>Leona Mullen Site</h1>
      <p>The greatest corn star who ever lived!</p>
    </div>
  );
}

export default App;