import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

const firebaseConfig = {
  apiKey: 'AIzaSyC7jJtgPfBB71cnZn_4LvdaQ22MP3mZbbk',
  authDomain: 'leonamullen-main.firebaseapp.com',
  projectId: 'leonamullen-main',
  storageBucket: 'leonamullen-main.appspot.com',
  messagingSenderId: '875146848790',
  appId: '1:875146848790:web:37bcf3cd563934a18f2337',
  measurementId: 'G-T13SZLQ0BW'
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
